
.tabmap {
    display: flex;
}

.tabmap .radar-view {
    position: relative;
}

.tabmap .radar-toolbar {
    display: flex;
    justify-content: flex-end;
}

.tabmap .search-field {
    background-image: url('../../../assets/images/search.svg');
    background-position: 5px 8px;
    background-repeat: no-repeat;
    background-size: 20px;
    margin-right: 4px;
    padding-left: 2rem !important;
    width: 8rem;
    transition: width .4s ease-in-out;
}
