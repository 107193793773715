
.buttonbar {
    padding: 0 1rem;
}

.buttonbar .btn {
    margin: 0 .1rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid darkgray;
}

.buttonbar span:first-child {
    margin-right: 1rem;
}

.buttonbar span:last-child {
    margin-left: 1rem;
}
