
.skilltree-mobility th {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: normal;
}

.skilltree-mobility td:not(:first-child) {
    font-size: 0.8rem;
}
