

/* first table row */

.treetable tr {
    cursor: default;
}

/* table row, first table column */

.treetable .first-column {
    display: flex;
    justify-content: left;
    align-items: center;
}

/* first column - indentation */

.treetable .node-indent {
    margin-left: .5rem;
}

/* first column - arrow */

.treetable .node-arrow img {
    width: 1rem;
    height: 1rem;
    transition: transform .2s;
}

.treetable .node-close {
    transform: rotate(0deg);
}

.treetable .node-open {
    transform: rotate(90deg);
}

/* first column, label */

.treetable .node-label {
    padding-left: .4rem;
}

/* drag/drop rendering */

.node-drop-line {
    transition: border-bottom .2s;
}

.node-drop-line.show {
    border-bottom: 3px solid #1967d2;
}

.dragging-image {
    position: absolute;
    left: -10000px;
    width: fit-content;
    padding: .3rem 1rem;
    color: white;
    border-radius: 5px;
    background: #1967d2;
}    

/* checkbox indeterminate state */

input[type='checkbox']:indeterminate {
    background-color: #0d6dfd35 !important;
    border-color: #0d6dfd35 !important;
}
