
.level-selector .userlevel {
    background-color: #cce0ff !important;
}

.level-selector .spillgroup {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.2rem;
    bottom: 2px;
    height: 0.3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    
    cursor: pointer;
    z-index: 1;
}

.spillgroup img {
    width: 0.3rem !important;
    height: 0.3rem !important;
}
