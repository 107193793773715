

.table-header-new {
    display: flex;
    align-items: flex-end;
    user-select: none;
}

.table-header-new.sortable {
    cursor: pointer;
}

.table-header-new img {
    height: 1rem;
    width:  1rem;
}
