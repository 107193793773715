
.mindchart {
    height: 13rem;
    font-size: .7rem;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.mindchart .category {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
}

.mindchart .value {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cfd2ff;
    border: 1px solid #c1c1c1;
    transition: all .2s ease-in-out;
}

.mindchart .label {
    text-align: center;
    height: 1.5rem;
}
