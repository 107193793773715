/* 
 * note: pseudo classes:
 *    -webkit- => chrome, opera, safari, edge
 *    -moz- => firefox
 */

/* main style */

.slider {

  --valuePercent: 50%;
  --thumbColor: white;

  display: flex;
  background-color: transparent;
}

/* slider components */

.slider > div:first-child {
  flex: 1;
  position: relative;
}

.slider-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.slider-input {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.slider-background {

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  pointer-events: none;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.slider > button {
  margin-left: 1rem;
  transition: opacity 0.4s;
}

/* customize standard slider */

.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  top: -0.34rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 0.15rem solid #0b59cf;
  background-color: white;
  background-color: var(--thumbColor);
}

.slider-input::-moz-range-thumb {
  -moz-appearance: none;
  position: relative;
  top: -0.34rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 0.15rem solid #0b59cf;
  background-color: white;
  background-color: var(--thumbColor);
}

.slider-input::-webkit-slider-runnable-track {
  cursor: pointer;
  height: 5px;
  border-radius: 3px;
  background-image: linear-gradient(#0b59cf, #0b59cf), linear-gradient(#eee, #eee);
  background-size: var(--valuePercent) 8px, 100% 8px;
  background-repeat: no-repeat;
}

.slider-input::-moz-range-track {
  cursor: pointer;
  height: 5px;
  border-radius: 3px;
  background-image: linear-gradient(#0b59cf, #0b59cf), linear-gradient(#eee, #eee);
  background-size: var(--valuePercent) 8px, 100% 8px;
  background-repeat: no-repeat;
}

/* slider disabled */

.slider-input.disabled::-webkit-slider-thumb {
  background: #6c757d;
  border: #6c757d;
}

.slider-input.disabled::-webkit-slider-runnable-track {
  background-image: linear-gradient(#6c757d, #6c757d), linear-gradient(#eee, #eee);
}

.slider-input.disabled::-moz-range-thumb {
  background: #6c757d;
  border: #6c757d;
}

.slider-input.disabled::-moz-range-track {
  background-image: linear-gradient(#6c757d, #6c757d), linear-gradient(#eee, #eee);
}

/* slider without value (unselected) */

.slider-input.unselected::-webkit-slider-thumb {
  visibility: hidden;
}

.slider-input.unselected::-webkit-slider-runnable-track {
  background-image: linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
}

.slider-input.unselected::-moz-range-thumb {
  visibility: hidden;
}

.slider-input.unselected::-moz-range-track {
  background-image: linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
}

/* background components */

.slider-background > div {
  position: relative;
  width: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider .userlevel {
  position: relative;
  left: 0.05rem;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  background-color: #cce0ff;
  border: 0.1rem solid #0b59cf;
}

.slider .spillgroup {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.2rem;
  height: 0.3rem;

  pointer-events: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
}

.spillgroup img {
  width: 0.3rem !important;
  height: 0.3rem !important;
}