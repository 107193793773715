
/* legend */

.genderchart .legend {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 0.8rem;
    list-style-type: none;
    padding: 0;
}

.genderchart .legend li {
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
}

.genderchart .legend div {
    width: 0.8rem;
    height: 0.6rem;
    margin-right: 0.2rem;
    border: 1px solid #d1d1d1;
}

/* chart */

.genderchart .chart {
    height: 2rem;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 0.8rem;
}

.genderchart .category {
    display: flex;
    align-items: center; 
    justify-content: center;
    padding: 0.3rem;
    border: 1px solid #d1d1d1;
    transition: all .2s ease-in-out;
}

/* colors */

.genderchart .male {
    background-color: #e6fff7;
}

.genderchart .category.male:hover {
    background-color: #d1fef0;
}

.genderchart .female {
    background-color: #fffedb;
}

.genderchart .category.female:hover {
    background-color: #fdfbbc;
}

.genderchart .na {
    background-color: #fef0d7;
}

.genderchart .category.na:hover {
    background-color: #fce6c0;
}

/* hover effect */
.genderchart .category:hover {
    cursor: pointer;
}



