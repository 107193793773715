
.skilltree-inventory .legend-autonomous::before {
    content: '\00a0\00a0\00a0\00a0';
    background-color: #c1c5ff;
    margin-right: 0.4rem;
}

.skilltree-inventory .barchart {
    height: 2rem;
    display: flex;
    font-size: 0.8rem;
}

.skilltree-inventory .barchart > div {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
}

.skilltree-inventory .leftbar {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    background-color: #dcdeff;
}

.skilltree-inventory .leftbar:hover {
    cursor: pointer;
    background-color: #c1c5ff;
}

.skilltree-inventory .rightbar {
    background-color: #f7f7f7;
}





