
.userlist {
    display: flex;
    flex-direction: column;
    font-size: .9rem;
}

.userlist .dropdown {
    display: flex;
    flex-direction: column;
}

.userlist .dropdown-item {
    cursor: pointer;
}

.userlist table tr {
    cursor: pointer;
}