
/* legend */

.ruler {
    margin-left: 4rem;
    margin-right: 1rem; 
    /* border: 1px solid blue; */
}

/* chart */

.agechart ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-left: 1rem;
    margin-right: 1rem;    
    flex-direction: column;
    font-size: 0.8rem;
}

.agechart li {
    display: flex;
    height: 1.6rem;
    margin: 1px 0;
    /* border: 1px solid red; */
}

.agechart .legend {
    display: flex;
    align-items: center;
    width: 3rem;
}

.agechart .value {
    flex: 1;
    display: flex;
    justify-content: center;
}

.agechart .value > span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d6bdff;
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding: 0 0.3rem;
}

.agechart .value > span:hover {
    background-color: #c39dff;
}
