
@import url("https://fonts.googleapis.com/css2?family=Lato"); 
@import url("https://fonts.googleapis.com/css2?family=Roboto"); 

body {
    margin: 0;
    font-family: 'Lato', sans-serif !important; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media print {
    header, footer {
        display: none !important;
    }
}

svg {
    width: 1rem;
    height: 1rem;
}    

.logo {
    height: 2.5rem;
}

.link {
    color: #0062cc;
    text-decoration: none;
}

/* all buttons a 1rem square */
.btn img, .keyrole-check {
    width: 1rem;
    height: 1rem;
}

/* restore bootstrap5 missing btn-xs */
.btn-xs {
    padding: 1px 2px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

/* same as bootstrap text-muted */
.text-disabled {
    color: #6c757d;
}

/* dropdown */

/* bugfix: safari inproperly put a border around dropdown */
.navbar .dropdown-toggle:focus  {
    outline: none; 
} 

.dropdown-language {
    min-width: 2rem;
}

/* import dialog */

.modal-alert {
    background: #fafafa;
    border-color: #c6c7c8;
}
