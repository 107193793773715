
.table-normalized th {
    font-size: 0.8rem;
    font-family: arial;
    font-weight: normal;
    text-align: center;
}

.table-normalized td {
    font-size: 0.9rem;
}

.table-normalized th:first-child {
    width: 30%;
}

.table-normalized .table-header {
    display: flex;
}

.table-normalized .table-header > div {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-normalized .table-header > div > div {
    cursor: pointer;
}

.table-normalized .table-header > button {
    margin-left: 1rem;
    visibility: hidden;
}

.table-normalized td:last-child > div {
    display: flex;
    justify-content: space-between;
}

.table-normalized  img {
    width: 1.3rem;
    height: 1.3em;
}

.table-normalized .skill-icon {
    width: 0.9rem;
    height: 0.9em;
}




