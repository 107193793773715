
.avatar {
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
}

.avatar-editable {
    cursor: pointer;
}

.avatar-croppable {
    width: 12rem;
    height: 12rem;
    background-color: white;
}
