
.skilltree-analysis th {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: normal;
}

.skilltree-analysis td:not(:first-child) {
    font-size: 0.8rem;
}

.skilltree-analysis .legends > span:first-child::before {
    content: '\00a0\00a0\00a0\00a0';
    background-color: #c1c5ff;
    margin-right: 0.4rem;
}

.skilltree-analysis .legends > span:last-child::before {
    content: '\00a0\00a0\00a0\00a0';
    background-color: #fec8f8;
    margin-right: 0.4rem;
}

.skilltree-analysis .barchart {
    height: 2rem;
    display: flex;
    font-size: 0.8rem;
}

.skilltree-analysis .barchart > div {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
}

.skilltree-analysis .left {
    padding: 0 1rem;
    background-color: #dcdeff;
}

.skilltree-analysis .left:hover {
    cursor: pointer;
    background-color: #c1c5ff;
}

.skilltree-analysis .right {
    padding: 0 1rem;
    background-color: #ffddfd;
}

.skilltree-analysis .right:hover {
    cursor: pointer;
    background-color: #fec8f8;
}

