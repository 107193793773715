
.table-declarative th {
    font-size: 0.8rem;
    font-family: arial;
    font-weight: normal;
    text-align: center;
}

.table-declarative tr {
    font-size: 0.9rem;
}

.table-declarative .skill-icon {
    width: 0.9rem;
    height: 0.9em;
}
