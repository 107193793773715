
.modal-fixed .modal-content {
    max-height: 95vh;
    min-height: 95vh;
}

.table-fixed {
    max-height: 63vh;
    overflow-y: auto;
}

.table-fixed table thead {
    top: 0;
    position: sticky;
    background: white;
}
