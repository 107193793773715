
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Printing */

    @media print { 
        .title, .thead.item {
            margin-right: 1000px !important;
            position:fixed !important;
        }
        #Phead { border: 2px solid #000 !important; }
        #new-logo { 
            /* background-image: url("image/back-logo.png"); */
            width: 124px;
            height: 66px;
        }
    
    }
   