
/* search field */
.search-bar .text-field {
    background-image: url('../../assets/images/search.svg');
    background-position: 5px 8px;
    background-repeat: no-repeat;
    background-size: 20px;
    margin-right: 4px;
    padding-left: 2rem !important;
    min-width: 10rem;
    transition: width .4s ease-in-out;
}