
.radar {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    transform-origin: left top;
    overflow: hidden;
    cursor: move;
    min-height: 70vh;
}

.radar .background-circles {
    fill: #efefef50;
    stroke: #dddddd;
}

/* axes */

.radar .axis, .radar svg .arrows {
    stroke: black;
    fill: black;

    font-weight: lighter;
}

/* profile */

.profile {
    cursor: pointer;
}

