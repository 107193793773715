
.table-header {
    font-size: 0.8rem;
    font-family: arial;
    text-align: center;
}

.table-header th {
    font-weight: normal;
}

.table-text-normal {
    font-size: 0.9rem;
}

.table-row {
    font-size: 0.9rem;
    font-family: arial;
    text-align: center;
}

.skill-icon {
    width: 1.2rem;
    height: 1.2em;
}

