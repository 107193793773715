
/* container */

.stepper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-center;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;

    --circle-size: 2.3rem;
    --top-margin: .6rem;
    --line-space: .2rem;
    --line-top: (var(--circle-size)/2 + var(--top-margin));
    --line-width: (var(--circle-size)/2 + var(--line-space));
}

/* step */

.stepper li {
    flex: 1; /*  flex 1 is the same as flex: 1 1 0 (grow, shrink, basis) */
    position: relative; /* to allow absolute horizontal links */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: var(--top-margin) .5rem;
}

/* spill */

.stepper li div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    font-weight: normal;
    color: darkgray;
    background: white;
    border: 1px solid darkgray;
    transition: background .4s ease-in-out;
}

.stepper li.active div:first-child {
    color: white;
    border: 1px solid #0d6efd;
    background: #0d6efd;
}

.stepper li.active div:nth-child(2) {
    font-weight: bold;
}

.stepper li.completed div:first-child {
    color: #0d6efd;
    border: 1px solid #0d6efd;
    background: white;
}

/* links between steps */

.stepper li:not(:first-child)::before {
    content: '';
    position: absolute;
    top: calc(var(--line-top));
    left: 0;
    width: calc(50% - var(--line-width));
    height: 3px;
    background: #e0e0e0;
}

.stepper li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: calc(var(--line-top));
    left: calc(50% + var(--line-width));
    width: calc(50% - var(--line-width) + 1px);
    height: 3px;
    background: #e0e0e0;
}

.stepper li.left-completed:not(:first-child)::before {
    background: #0d6efd;
}

.stepper li.right-completed:not(:last-child)::after {
    background: #0d6efd;
}

/* panel */

.stepper-panel {
    opacity: 0;
    position: absolute;
    left: -200vw;
    transition: .3s ease-in;
}

.stepper-panel.active {
    opacity: 1;
    position: inherit;
}
