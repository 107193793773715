
#opportunities {
    position: relative;
}

#opportunities-toolbar {
    position: absolute;
    top:   .5rem;
    right: 1.5rem;
}
