
.title-bar {
    display: flex;
}

.title-bar > div {
    display: flex;
    align-items: flex-end;
    font-size: 1rem;    
    color: #898989;
}

.title-bar div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-left: 1rem;
}

.title-bar .counter {
    padding-left: .5rem;
    padding-bottom: .2rem;
    font-size: .6rem;
}

.title-bar .text-field {
    background-image: url('../../assets/images/search.svg');
    background-position: 5px 8px;
    background-repeat: no-repeat;
    background-size: 20px;
    margin-right: 4px;
    padding-left: 2rem !important;
    min-width: 10rem;
    transition: width .4s ease-in-out;
}
