
.user-card {
    width: 18rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.user-card .title {
    font-size: 0.9em;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 1rem;
}

.user-card .user-data {
    font-size: 0.8rem;
}

