
.login-form {
    width: 40rem;
    padding: 5%;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.login-form .btn-login {
    width: 9rem;
}

.company-picture {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    background-color: white;
}