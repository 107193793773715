
.sortable-header {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
}

.sortable-header img {
    height: 1rem;
    width:  1rem;
}